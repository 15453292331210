var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.list.pages[_vm.listInput.page])?_c('div',{staticClass:"md-content md-table-content UserList responsive"},[_c('md-table',{staticClass:"globe-activity md-table-global-sales",attrs:{"value":_vm.list.pages[_vm.listInput.page].items ? _vm.list.pages[_vm.listInput.page].items : []},scopedSlots:_vm._u([{key:"md-table-row",fn:function(ref){
var item = ref.item;
return _c('md-table-row',{},[_c('md-table-cell',{attrs:{"md-label":"Name"}},[_vm._v(_vm._s(item.title)+" "+_vm._s(item.name))]),_c('md-table-cell',{attrs:{"md-label":"Email"}},[_vm._v(_vm._s(item.email))]),_c('md-table-cell',{attrs:{"md-label":"Phone"}},[_vm._v(_vm._s(item.cellNumber))]),(_vm.listPage.module == 'doctors')?_c('md-table-cell',{attrs:{"md-label":"Act as Reader"}},[_vm._v(_vm._s(item.isReader == 1 ? "Yes" : "No"))]):_vm._e(),_c('md-table-cell',{attrs:{"md-label":"Status"}},[(item.statusText == 'Active')?_c('div',[_c('span',{class:_vm.getStatusClass(item),staticStyle:{"font-weight":"500","color":"#32cd32"}},[_c('b',[_vm._v(_vm._s(item.statusText))])])]):(item.statusText == 'Approval Pending')?_c('div',[_c('span',{class:_vm.getStatusClass(item),staticStyle:{"font-weight":"500","color":"orange"}},[_c('b',[_vm._v(_vm._s(item.statusText))])])]):_c('div',[_c('span',{class:_vm.getStatusClass(item),staticStyle:{"font-weight":"500","color":"red"}},[_c('b',[_vm._v(_vm._s(item.statusText))])])])]),_c('md-table-cell',{staticClass:"text-center",attrs:{"md-label":"Action"}},[(item.isActionLoading)?_c('div',[_c('spinner',{attrs:{"diameter":23}})],1):_c('div',[(
              item.status == _vm.PersonStatus.Active ||
              item.status == _vm.PersonStatus.InActive
            )?_c('div',{staticClass:"d-flex user-actions"},[(item.status == _vm.PersonStatus.Active)?_c('span',[_c('md-icon',{staticClass:"edit-icon",nativeOn:{"click":function($event){return _vm.handleActiveClick({
                    userID: item.userID,
                    personEntryID: item.personEntryID,
                    isActive: false,
                  })}}},[_vm._v("close")]),_c('md-tooltip',[_vm._v("Deactivate")])],1):_c('span',[_c('md-icon',{staticClass:"edit-icon",nativeOn:{"click":function($event){return _vm.handleActiveClick({
                    userID: item.userID,
                    personEntryID: item.personEntryID,
                    isActive: true,
                  })}}},[_vm._v("done")]),_c('md-tooltip',[_vm._v("Activate")])],1),_c('span',[(item.status != _vm.PersonStatus.ApprovalPending)?_c('md-icon',{staticClass:"edit-icon",nativeOn:{"click":function($event){return _vm.handleEditClick(item)}}},[_vm._v("edit")]):_vm._e(),_c('md-tooltip',[_vm._v("Edit")])],1),_c('span',[_c('md-icon',{staticClass:"delete-icon",nativeOn:{"click":function($event){return _vm.handleDeleteClick({
                    userID: item.userID,
                    personEntryID: item.personEntryID,
                  })}}},[_vm._v("delete")]),_c('md-tooltip',[_vm._v("Delete")])],1),(item.isAccountCreated)?_c('span',[_c('md-icon',{staticClass:"edit-icon",nativeOn:{"click":function($event){return _vm.enableChangePassword(item)}}},[_vm._v("manage_accounts")]),_c('md-tooltip',[_vm._v("Change Password")])],1):_vm._e()]):_c('div',[_c('router-link',{staticClass:"btn-small-danger",attrs:{"to":_vm.$route.path +
                (_vm.listPage.module ? '/' + _vm.listPage.module : '') +
                '/' +
                item.personEntryID +
                '/approvals'}},[(item.status == _vm.PersonStatus.ApprovalPending)?_c('md-button',{staticClass:"md-danger-outline",staticStyle:{"width":"80%"}},[_vm._v("Review")]):_c('md-button',{staticClass:"md-red-outline",staticStyle:{"width":"80%"}},[_vm._v("Review again")])],1)],1)])])],1)}}],null,false,2402317177)}),_c('md-dialog',{attrs:{"md-active":_vm.changePasswordForm.showChangePassword},on:{"update:mdActive":function($event){return _vm.$set(_vm.changePasswordForm, "showChangePassword", $event)},"update:md-active":function($event){return _vm.$set(_vm.changePasswordForm, "showChangePassword", $event)}}},[_c('div',{staticClass:"modal-size-medium"},[_c('div',{staticClass:"modal-header"},[_vm._v(" Change Password for '"+_vm._s(_vm.changePasswordForm.userName)+"' ")]),_c('div',{staticClass:"modal-content"},[_c('change-password')],1),_c('div',{staticClass:"modal-footer"},[_c('md-button',{staticClass:"btn-outline",on:{"click":function($event){_vm.changePasswordForm.showChangePassword = false}}},[_vm._v("Cancel ")]),(_vm.changePasswordForm.processing)?_c('spinner',{staticStyle:{"padding":"10px"},attrs:{"diameter":25}}):_c('md-button',{staticClass:"md-primary",on:{"click":function($event){return _vm.submitChangePassword()}}},[_vm._v("Proceed ")])],1)])])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }