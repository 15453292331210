var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('loading',{attrs:{"active":!_vm.master.isRoleLoaded,"can-cancel":true}}),_c('div',{staticClass:"md-layout"},[_vm._m(0),_c('div',{staticClass:"md-layout-item md-size-100 pt-2"},[_c('select-input',{staticStyle:{"margin":"0","max-width":"250px"},attrs:{"name":"Role","options":_vm.master.roleList.map(function (x) { return ({
            value: x.id,
            text: x.name,
          }); })},model:{value:(_vm.listPage.selectedRoleId),callback:function ($$v) {_vm.$set(_vm.listPage, "selectedRoleId", $$v)},expression:"listPage.selectedRoleId"}})],1)]),_c('list-layout',{attrs:{"title":_vm.listPage.module
        ? _vm.listPage.module.charAt(0).toUpperCase() +
          _vm.listPage.module.slice(1) +
          ' List'
        : ''},scopedSlots:_vm._u([{key:"card-title",fn:function(){return [_vm._v(" List ")]},proxy:true},{key:"header-actions",fn:function(){return [(_vm.listPage.module == 'doctors')?_c('checkbox-single-input',{attrs:{"name":"onlyReader","label":"Readers"},model:{value:(_vm.listFilters.onlyReader),callback:function ($$v) {_vm.$set(_vm.listFilters, "onlyReader", $$v)},expression:"listFilters.onlyReader"}}):_vm._e(),_c('primary-button',{staticStyle:{"margin-right":"15px"},attrs:{"toolTip":"Add New User"},on:{"click":function($event){return _vm.handleAddClick()}}},[_vm._v("Add ")])]},proxy:true},{key:"content",fn:function(){return [_c('user-list-table')]},proxy:true}])})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"md-layout-item md-size-100"},[_c('h3',{staticClass:"md-title m-0"},[_vm._v("Users")])])}]

export { render, staticRenderFns }