<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <ValidationListener
        @getIsValid="(isValid) => (changePasswordForm.isValid = isValid)"
      >
        <ValidationHandler
          :name="changePasswordForm.password"
          rules="required"
          v-slot="{ error }"
        >
          <text-input
            type="password"
            label="New Password"
            placeholder="New Password"
            icon="lock_outline"
            :required="true"
            v-model="changePasswordForm.password"
            :error="error"
            icons="password"
          />
        </ValidationHandler>
        <ValidationHandler
          :name="changePasswordForm.confirmPassword"
          rules="required"
          v-slot="{ error }"
        >
          <text-input
            type="password"
            label="Confirm Password"
            placeholder="Confirm Password"
            icon="lock_outline"
            :required="true"
            v-model="changePasswordForm.confirmPassword"
            :error="error"
            icons="password"
          />
        </ValidationHandler>
      </ValidationListener>
    </div>
  </div>
</template>
<script>
export default {
  inject: ["changePasswordForm"],
};
</script>
