<template>
  <div>
    <loading :active="!master.isRoleLoaded" :can-cancel="true"></loading>
    <div class="md-layout">
      <div class="md-layout-item md-size-100">
        <h3 class="md-title m-0">Users</h3>
      </div>
      <div class="md-layout-item md-size-100 pt-2">
        <select-input
          name="Role"
          :options="
            master.roleList.map((x) => ({
              value: x.id,
              text: x.name,
            }))
          "
          v-model="listPage.selectedRoleId"
          style="margin: 0; max-width: 250px"
        />
        <!-- <select-input
          name="filter"
          displayName="Filter"
          :options="
            Status.map((x) => ({
              value: x.value,
              text: x.text,
            }))
          "
          v-model="listInput.filterText"
          :required="false"
          style="margin: 0; max-width: 250px"
        /> -->
      </div>
    </div>
    <list-layout
      :title="
        listPage.module
          ? listPage.module.charAt(0).toUpperCase() +
            listPage.module.slice(1) +
            ' List'
          : ''
      "
    >
      <template v-slot:card-title> List </template>
      <template v-slot:header-actions>
        <checkbox-single-input
          v-if="listPage.module == 'doctors'"
          name="onlyReader"
          label="Readers"
          v-model="listFilters.onlyReader"
        />
        <primary-button
          toolTip="Add New User"
          style="margin-right: 15px"
          @click="handleAddClick()"
          >Add
        </primary-button>
      </template>
      <template v-slot:content>
        <user-list-table></user-list-table>
      </template>
    </list-layout>
  </div>
</template>

<script>
import PrimaryButton from "../../../components/controls/button/PrimaryButton.vue";
import ListLayout from "../../../components/layouts/list-layout/ListLayout.vue";
import UserListTable from "./UserListTable.vue";
export default {
  components: { ListLayout, UserListTable, PrimaryButton },
  inject: ["master", "listPage", "listInput", "Status", "listFilters"],
  methods: {
    handleAddClick() {
      this.$router.push(
        this.$route.path +
          (this.listPage.module ? "/" + this.listPage.module : "") +
          "/add"
      );
    },
  },
};
</script>
