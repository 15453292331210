<template>
  <div
    class="md-content md-table-content UserList responsive"
    v-if="list.pages[listInput.page]"
  >
    <md-table
      :value="
        list.pages[listInput.page].items ? list.pages[listInput.page].items : []
      "
      class="globe-activity md-table-global-sales"
    >
      <!-- md-fixed-header -->
      <md-table-row slot="md-table-row" slot-scope="{ item }">
        <md-table-cell md-label="Name"
          >{{ item.title }} {{ item.name }}</md-table-cell
        >
        <md-table-cell md-label="Email">{{ item.email }}</md-table-cell>
        <md-table-cell md-label="Phone">{{ item.cellNumber }}</md-table-cell>
        <md-table-cell
          md-label="Act as Reader"
          v-if="listPage.module == 'doctors'"
          >{{ item.isReader == 1 ? "Yes" : "No" }}</md-table-cell
        >
        <md-table-cell md-label="Status">
          <div v-if="item.statusText == 'Active'">
            <span
              :class="getStatusClass(item)"
              style="font-weight: 500; color: #32cd32"
              ><b>{{ item.statusText }}</b></span
            >
          </div>
          <div v-else-if="item.statusText == 'Approval Pending'">
            <span
              :class="getStatusClass(item)"
              style="font-weight: 500; color: orange"
              ><b>{{ item.statusText }}</b></span
            >
          </div>

          <div v-else>
            <span
              :class="getStatusClass(item)"
              style="font-weight: 500; color: red"
              ><b>{{ item.statusText }}</b></span
            >
          </div>
        </md-table-cell>
        <md-table-cell md-label="Action" class="text-center">
          <div v-if="item.isActionLoading">
            <spinner :diameter="23"></spinner>
          </div>
          <div v-else>
            <div
              v-if="
                item.status == PersonStatus.Active ||
                item.status == PersonStatus.InActive
              "
              class="d-flex user-actions"
            >
              <span v-if="item.status == PersonStatus.Active">
                <md-icon
                  class="edit-icon"
                  @click.native="
                    handleActiveClick({
                      userID: item.userID,
                      personEntryID: item.personEntryID,
                      isActive: false,
                    })
                  "
                  >close</md-icon
                >
                <md-tooltip>Deactivate</md-tooltip>
              </span>
              <span v-else>
                <md-icon
                  class="edit-icon"
                  @click.native="
                    handleActiveClick({
                      userID: item.userID,
                      personEntryID: item.personEntryID,
                      isActive: true,
                    })
                  "
                  >done</md-icon
                >
                <md-tooltip>Activate</md-tooltip>
              </span>
              <span>
                <md-icon
                  class="edit-icon"
                  v-if="item.status != PersonStatus.ApprovalPending"
                  @click.native="handleEditClick(item)"
                  >edit</md-icon
                >
                <md-tooltip>Edit</md-tooltip>
              </span>
              <span>
                <md-icon
                  class="delete-icon"
                  @click.native="
                    handleDeleteClick({
                      userID: item.userID,
                      personEntryID: item.personEntryID,
                    })
                  "
                  >delete</md-icon
                >
                <md-tooltip>Delete</md-tooltip>
              </span>
              <span v-if="item.isAccountCreated">
                <md-icon
                  class="edit-icon"
                  @click.native="enableChangePassword(item)"
                  >manage_accounts</md-icon
                >
                <md-tooltip>Change Password</md-tooltip>
              </span>
            </div>
            <div v-else>
              <router-link
                class="btn-small-danger"
                :to="
                  $route.path +
                  (listPage.module ? '/' + listPage.module : '') +
                  '/' +
                  item.personEntryID +
                  '/approvals'
                "
              >
                <!-- class="md-danger-outline" -->
                <md-button
                  class="md-danger-outline"
                  v-if="item.status == PersonStatus.ApprovalPending"
                  style="width: 80%"
                  >Review</md-button
                >
                <md-button class="md-red-outline" v-else style="width: 80%"
                  >Review again</md-button
                >
              </router-link>
            </div>
          </div>
        </md-table-cell>
      </md-table-row>
    </md-table>
    <md-dialog :md-active.sync="changePasswordForm.showChangePassword">
      <div class="modal-size-medium">
        <div class="modal-header">
          Change Password for '{{ changePasswordForm.userName }}'
        </div>
        <div class="modal-content">
          <change-password />
        </div>
        <div class="modal-footer">
          <md-button
            class="btn-outline"
            @click="changePasswordForm.showChangePassword = false"
            >Cancel
          </md-button>
          <spinner
            :diameter="25"
            style="padding: 10px"
            v-if="changePasswordForm.processing"
          ></spinner>
          <md-button v-else class="md-primary" @click="submitChangePassword()"
            >Proceed
          </md-button>
        </div>
      </div>
    </md-dialog>
  </div>
</template>
<script>
import { UserStatus } from "../../../constants/User";
import { PersonStatus } from "../../../constants/Person";
import ChangePassword from "./ChangePassword";

export default {
  inject: [
    "list",
    "listInput",
    "listPage",
    "changePasswordForm",
    "handleDeleteClick",
    "handleActiveClick",
    "Permissions",
    "enableChangePassword",
    "submitChangePassword",
  ],
  data() {
    return {
      UserStatus,
      PersonStatus,
    };
  },
  methods: {
    handleEditClick(item) {
      this.$router.push(
        this.$route.path +
          (this.listPage.module ? "/" + this.listPage.module : "") +
          "/edit/" +
          item.personEntryID
      );
    },
    getStatusClass(item) {
      if (item.status == PersonStatus.ApprovalPending)
        return "approval-pending-status";
      else if (item.status == PersonStatus.Active) return "active-status";
      else if (item.status == PersonStatus.InActive) return "inActive-status";
      else if (item.status == PersonStatus.Rejected) return "rejected-status";
    },
  },
  components: {
    ChangePassword,
  },
};
</script>

<style lang="scss" scoped>
.globe-activity {
  height: calc(100vh - 400px);
  overflow: auto;
}
.md-table-cell {
  //padding: 2px 8px;
  font-size: 0.8rem;
  height: 45px;
}
.md-table-row {
  color: rgb(0, 6, 97);
}
.md-table-global-sales .md-table-row .md-table-cell:first-child {
  font-weight: 500;
}
.UserList.responsive td:nth-of-type(1):before {
  content: "Name";
}
.UserList.responsive td:nth-of-type(2):before {
  content: "Email";
}
.UserList.responsive td:nth-of-type(3):before {
  content: "Phone";
}
.UserList.responsive td:nth-of-type(4):before {
  content: "Status";
}
.UserList.responsive td:nth-of-type(5):before {
  content: "Action";
}
.user-actions span {
  padding-right: 10px;
}
</style>
